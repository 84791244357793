<template>
  <div class="content">
    <sub-title>공지사항</sub-title>
    <div class="notice">
      <div class="notice_list" v-for="(item,index) in eventList" :key="index">
        <div class="n">
          <img src="../../../assets/images/icon/common/event.png">
        </div>
        <div class="t" :style="{'color':item.titleColor}" @click="showContent(item.id)">
          {{item.title}}
        </div>
        <div class="c" style="text-align: center;padding: 30px 10px 10px 10px"
             v-if="item.contentShow && null != item.eventImg && (item.eventImg.indexOf('yew')!=-1 || item.eventImg.indexOf('yew2')!=-1)">
          <img :src="item.eventImg" alt="이벤트이미지">
        </div>
        <div class="c" style="padding: 30px 10px 10px 10px" v-if="item.contentShow">
          <div v-if="null != item.content" v-html="item.content"
               style="box-sizing: border-box;padding: 20px;cursor: pointer"></div>
        </div>
      </div>
    </div>
    </div>

</template>


<script>

import {
  coffetimevent,
  getEvent,
  getNoticeList,
  owlevent,
  payback,
  payback4referrer,
  run369
} from "@/network/userRequest";
import {checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

    export default {
      name: "Event",
      mixins: [postionMixin,checkLoginOnCreateMinxin],
      components: {  SubTitle },
      data() {
        return {
          eventList: [],
          pageNum: 1,
          pageSize: 100,
          total: 1,
          sportsConst,
          clickNumber: 0,
          position: "이벤트",
        }
      },
      methods: {

        initEvent() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          getEvent(this.pageNum, this.pageSize).then(res => {
            if (res.data.success) {
              this.eventList = res.data.data
              this.total = res.data.total
              this.eventList.map(item => {

                if (item.id == this.clickNumber) {
                  this.$set(item, 'contentShow', true)
                } else {
                  this.$set(item, 'contentShow', false)
                }
              })
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        },
        showContent(id) {
          if (id === this.clickNumber) {
            this.clickNumber = 0
          } else {
            this.clickNumber = id;
          }
          this.eventList.map(item => {
            if (item.id == id) {
              this.$set(item, 'contentShow', !item.contentShow)
            }

          })
        },
        pageChange(page) {
          this.pageNum = page
          this.initEvent()
        },
      },
      created() {
        this.clickNumber = this.$route.query.id
        this.initEvent()
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontnotice.css");


</style>